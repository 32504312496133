<template>
  <div>
    <div class="row">
      <div class="col-12 d-flex justify-content-end">
        <b-button :disabled="printing" variant="secondary" @click="exportToPDF">
          <b-spinner
            v-if="printing"
            small
            class="text-white mr-1"
            label="Loading..."
          ></b-spinner
          ><i class="mdi mdi-printer mr-1"></i> Télécharger
        </b-button>
      </div>
    </div>
    <section ref="document" id="document">
      <div class="container-fluid rounded p-4 mt-4 bg-white">
        <div class="row mt-3 col-12">
          <div class="col-12 p-0">
            <p class="h2 text-secondary">
              <i style="font-size: 22px" class="mdi mdi-firework"></i>
              <span style="font-size: 18px" class="font-weight-light"
                >Fiche Vision:
              </span>
              <span style="font-size: 22px">{{
                selectedVisionItem.libelle
              }}</span>
            </p>
          </div>
        </div>
        <div class="row col-12 p-0 mt-4 mx-auto mb-4">
          <div class="col-md-4 col-12 p-1">
            <strategic-vision
              :fileModActivated="!desactivateAllFileModifications"
              @modifyFileDo="
                () => {
                  desactivateAllFileModifications = true;
                }
              "
              :disableButton="!disable"
              :vision="selectedVisionItem"
            />
          </div>
          <div class="col-md-4 col-12 p-1">
            <Progress
              margin
              @leaveChange="desactivateAllFileModifications = false"
              :fileModActivated="!desactivateAllFileModifications"
              @modifyFileDo="
                () => {
                  desactivateAllFileModifications = true;
                }
              "
              :disableButton="!disable"
              :vision="selectedVisionItem"
            />
          </div>
          <div class="col-md-4 col-12 p-1">
            <coverage
              @leaveChange="desactivateAllFileModifications = false"
              :fileModActivated="!desactivateAllFileModifications"
              @modifyFileDo="
                () => {
                  desactivateAllFileModifications = true;
                }
              "
              :disableButton="!disable"
              :vision="selectedVisionItem"
            />
          </div>
        </div>

        <div class="row col-12 p-0 mx-auto">
          <span class="h4 text-secondary mr-auto my-auto"
            >Objectifs strategiques
          </span>
        </div>
        <div class="col-12 p-0">
          <hr class="mt-1 mb-3 bg-secondary" />
        </div>

        <strategic-goal-list
          :objectifs="objectifs"
          :fields="strategicGoal.tableFields"
          :responsables="responsables"
          :organisations="organisations"
        />
        <div class="row col-12 p-0 mx-auto">
          <span class="h4 text-secondary mr-auto my-auto"
            >Carthographie de déploiement
          </span>
        </div>
        <div class="col-12 p-0">
          <hr class="mt-1 mb-3 bg-secondary" />
        </div>

        <map-box
          id="map"
          class="col-12 mx-auto p-0"
          :vision="selectedVisionItem"
          :contr="contr"
        />
      </div>
    </section>
  </div>
</template>
<script>
import Coverage from "./components/Coverage.vue";
import StrategicVision from "./components/StrategicVision.vue";
import Progress from "./components/Progress.vue";
import html2pdf from "html2pdf.js";
import { mapGetters } from "vuex";
import StrategicGoalList from "./components/StrategicGoalList.vue";
import MapBox from "@/components/custom/MapBox.vue";
import { concat } from "lodash";
// import domtoimage from 'dom-to-image';
// import { loadImage } from 'blueimp-load-image';
// import { saveAsPng, saveAsJpeg } from 'save-html-as-image';
// import Html2Img from 'html2img'
// import html2canvas from "html2canvas";
//import jsPDF from 'jspdf'
//import mapboxgl from "mapbox-gl";
export default {
  components: {
    StrategicVision,
    Coverage,
    Progress,
    StrategicGoalList,
    MapBox,
  },

  data: () => ({
    selectedVisionItem: {},
    desactivateAllFileModifications: false,
    printing: false,
    objectifs: [],
    strategicGoal: {
      tableItems: [],
      tableFields: [
        {
          key: "libelle",
          label: "Objectif stratégique",
          sortable: false,
        },
        { key: "desription", label: "Description", sortable: false },
        {
          key: "organisationResp",
          label: "Organisation responsable",
          sortable: false,
        },
        { key: "responsable", label: "Responsable", sortable: false },
        { key: "kpis", label: "KPIs", sortable: false },
        { key: "avancement", label: "Avancement", sortable: false },
        { key: "alignment", label: "Alignement", sortable: false },
      ],
    },
    contr: [],
  }),
  props: {
    disable: Boolean,
  },
  created() {
    this.$store.dispatch("users/fetchAllResponsables");
    this.$store.dispatch("status/fetchVisionStatus");
    this.$store.dispatch("status/fetchAllStatus");
    this.$store.dispatch("organisation/fetchAllOrganisations");
    this.$store.dispatch("vision/fetchVision", this.$route.params.id);
  },
  watch: {
    VISION() {
      this.selectedVisionItem = this.VISION;
      var table = ["in", "iso_3166_1"];
      var contr = [];
      for (var i = 0; i < this.VISION.couvertures.length; i++) {
        contr[i] = this.VISION.couvertures[i].countryCode.toUpperCase();
      }
      for (var j = 0; j < contr.length; j++) {
        if (contr[j] == "MA") contr.push("EH");
      }
      table = concat(table, contr);
      this.contr = table;

      this.objectifs = this.VISION.objectifs
        ? this.VISION.objectifs.map((objectif) => ({
            ...objectif,
            old: true,
            actions: ["edit", "delete"],
          }))
        : this.objectifs;
    },
  },
  computed: {
    ...mapGetters("users", ["RESPONSABLES"]), //get resposables
    ...mapGetters("status", ["VISIONS_STATUS", "STATUS"]), //get all status
    ...mapGetters("organisation", ["ORGANISATIONS"]), //get all organisations
    ...mapGetters("vision", ["VISION"]), //get current vision
    responsables() {
      //return array of responsables
      return this.RESPONSABLES.map((responsable) => ({
        ...responsable,
        full_name: responsable.firstname + " " + responsable.lastname,
      }));
    },
    statusVisions() {
      return this.VISIONS_STATUS;
    },
    organisations() {
      return this.ORGANISATIONS;
    },
  },
  methods: {
    exportToPDF() {
      this.printing = true;

      html2pdf()
        .set({
          margin: 1,
          filename: "vision.pdf",
          html2canvas: {
            dpi: 192,
            letterRendering: true,
            useCORS: true,
            width: 2000,
            // height: 1000,
            // scale: 2,
            removeContainer: true,
          },
          image: { type: "jpg", quality: 0.95 },
          jsPDF: { unit: "in", format: "A1", orientation: "portrait" },
          pagebreak: { mode: "avoid-all", after: ".page" },
        })
        .from(this.$refs.document)
        .save()
        .then(() => {
          this.printing = false;
        });
    },
  },
};
</script>
<style>
.print-map-xl {
  width: 100% !important;
  min-height: 100vh !important;
  /* background: green ; */
}
</style>
